.container {
  display: flex;
  flex-direction: column;   
  gap: 10px;
}

.entry {
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
  justify-content: space-between;
}