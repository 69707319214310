.container {
  z-index: 99;
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  bottom: var(--hud-height);
  padding: 20px 20px 160px;
  flex-direction: column;
  gap: 20px;
  width: var(--sidebar-width);
  overflow: auto;
  scrollbar-width: none;
  box-sizing: border-box;

  background-color: var(--sidebar-background-color);
  backdrop-filter: blur(var(--sidebar-background-blur));
}

.container button {
  justify-content: start;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.group button {
  justify-content: start;
}

.joysticks {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--ui-color);
  border-radius: 5px;
}

.joysticks button {
  cursor: var(--resize-all) !important;
}

.joysticks button:hover {
  background-color: var(--ui-color-hover) !important;
}

.joysticks button:before,
.joysticks button:after {
display: none;
}