.container {
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: var(--hud-height);
}

.layout {
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  background-color: var(--sidebar-background-color);
  backdrop-filter: blur(var(--sidebar-background-blur));
}