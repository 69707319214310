.container {
  position: fixed;
  bottom: var(--hud-height);
  left: 0;
  right: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 10px 0;
  max-width: 100%;
  overflow: auto;
  transition: opacity 200ms ease-in-out;
  text-align: center;
  flex-wrap: wrap;
}

.phrase {
  display: inline;
}

@media (max-width: var(--mobile-breakpoint)) {
  .phrase {
    display: block;
  }
}