.container {
  position: absolute;
  width: 0;
  height: 0;
}

.tooltip {
  position: relative;
  left: 100px;
  padding: 5px 10px 10px;
  border-radius: 0 5px 0 5px;
  background: var(--tooltip-background-color);
  color: var(--ui-color);
  font-size: 14px;
  pointer-events: none;
  border: 1px solid var(--ui-color);
  font-weight: bold;
  width: max-content;
  min-width: 300px;
  backdrop-filter: blur(var(--sidebar-background-blur));
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}