input[type="checkbox"] {
  position: relative;
  appearance: none;
  border: 1px solid var(--ui-color);
  width: 14px;
  height: 14px;
  border-radius: 3px;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  cursor: var(--cursor) !important; 
}
input[type="checkbox"]:hover {
  border-color: var(--ui-color-hover);
}

input[type="checkbox"]:checked {
  position: relative;
  appearance: none;
  background-color: var(--ui-color);
}
input[type="checkbox"]:checked:hover {
  background-color: var(--ui-color-hover);
}

label {
  cursor: var(--cursor);
  color: var(--ui-color);
  transition: color 200ms ease-in-out;
}
label:hover {
  color: var(--ui-color-hover);
}