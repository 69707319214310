:root {
  --ui-color: #fa7;
  --ui-color-hover: #fda;
  --ui-background: radial-gradient(circle at 0%, #fa70, #fa70);
  --ui-background-hover: radial-gradient(circle at 0%, #fa73, #fa71);
  --ui-background-active: radial-gradient(circle at 0%, #fa74, #fa72);
  --sidebar-background-color: #00000d88;
  --sidebar-width: 375px;
  --sidebar-background-blur: 10px;
  --tooltip-background-color: #00000dbb;
  --hud-height: 50px;

  --mobile-breakpoint: 480px;
}

@font-face {
  font-family: 'Jura';
  src: url('/public/Jura-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Jura';
  src: url('/public/Jura-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Jura';
  src: url('/public/Jura-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Jura';
  src: url('/public/Jura-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Jura';
  src: url('/public/Jura-Bold.ttf') format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: Jura, Consolas, 'Courier New',
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  color: var(--ui-color);
  background-color: #000;
  overflow: hidden;
  user-select: none;
}

code {
  font-family: Jura, Consolas, 'Courier New', 
    monospace;
}

small {
  font-size: 80%;
}

#root, #canvas-container {
  height: 100vh;
}

[data-uppercase="true"] {
  text-transform: uppercase;
}

[data-header="true"] {
  position: relative;
}
[data-header="true"]:after {
  content: "";
  position: absolute;
  height: 3px;
  bottom: -3px;
  left: 0;
  right: 0;
  background: var(--ui-background-active);
  border-radius: 0 3px 0 3px;
}

[data-muted] {
  opacity: 0.66;
}

[data-color="danger"] {
  color: #f46;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

[data-pulse="true"] {
  animation: pulse 2s ease-in-out infinite;
}

a {
  color: var(--ui-color);
  transition: all 200ms ease-in-out;
}

a:hover, a:active, a:focus {
  color: var(--ui-color-hover);
}

[data-flex="true"] {
  display: flex;
}
[data-align-center="true"] {
  align-items: center;
}