.container {
  z-index: 99;
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  bottom: var(--hud-height);
  padding: 20px 20px 140px;
  flex-direction: column;
  gap: 10px;
  width: var(--sidebar-width);
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: none;

  background-color: var(--sidebar-background-color);
  backdrop-filter: blur(var(--sidebar-background-blur));
}

.container button {
  justify-content: start;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.group button {
  justify-content: start;
}