button {
  position: relative;
  background-color: transparent;
  padding: 5px 10px;
  border: 1px solid var(--ui-color);
  color: var(--ui-color);
  border-radius: 0 5px 0 5px;
  letter-spacing: 1px;
  font-family: Jura, Consolas, 'Courier New',
    monospace;
  font-size: 80%;
  text-transform: uppercase;
  font-weight: 700;
  cursor: var(--cursor);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: pre;
  gap: 5px;
  background: var(--ui-background);

  transition: background 200ms ease-in-out, border-color 200ms ease-in-out, color 200ms ease-in-out;
}

button:hover,
button:focus {
  color: var(--ui-color-hover);
  border-color: var(--ui-color-hover);
}

button:focus {
  outline: none;
}

button:before,
button:after {
  content: "";
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 200ms ease-in-out;
}

button:before {
  top: 0;
  left: 0;
  border-top: 1px solid var(--ui-color);
  border-left: 1px solid var(--ui-color);
}

button:after {
  bottom: 0;
  right: 0;
  border-bottom: 1px solid var(--ui-color);
  border-right: 1px solid var(--ui-color);
}

button:hover:before,
button:hover:after,
button:focus:before,
button:focus:after {
  width: 10px;
  height: 10px;
  opacity: 1;
}

button:hover:before,
button:focus:before {
  top: -4px;
  left: -4px;
  border-top-color: var(--ui-color-hover);
  border-left-color: var(--ui-color-hover);
}

button:hover:after,
button:focus:after {
  bottom: -4px;
  right: -4px;
  border-bottom-color: var(--ui-color-hover);
  border-right-color: var(--ui-color-hover);
}

button[data-active="true"] {
  background: var(--ui-background-active);
}

button svg {
  flex-shrink: 0;
}